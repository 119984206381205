import React from "react"
import styled from "styled-components"

interface RichTextContainerI {
  richText: string
}

export const RichTextContainer = ({ richText }: RichTextContainerI) => {
  return <Container dangerouslySetInnerHTML={{ __html: richText }} />
}

const Container = styled.div`
  /* border: 1px solid red;  */
  width: 100%;
  word-wrap: break-word;
  > h1 {
    margin-bottom: 1rem;
  }
  > h2 {
    margin-bottom: 0.1rem;
    > span {
      margin: 0 !important;
      padding: 0 !important;
    }
  }
  > h3 {
    margin-bottom: 0.5rem;
  }
  > h4 {
    margin-bottom: 1rem;
  }
  > h5 {
    font-size: 1.625rem;
    margin-bottom: 1rem;
  }
  > h6 {
    margin-bottom: 0.5rem;
  }
  > p {
    margin-bottom: 0.5rem;
    font-weight: 200px !important;
    > span {
      margin: 0px !important;
      padding: 0 !important;
    }
  }

  > hr {
    margin-bottom: 2rem;
  }
  > br {
    /* display: block; */

    display: none;
  }
`
