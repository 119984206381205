import React from "react"
import styled from "styled-components"
import { RichTextContainer } from "./RichTextContainer"
import { LazyLoadImage, trackWindowScroll } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"
import { Container } from "@material-ui/core"
interface IImageWithSplitTextOverlap {
  data: any
}

const ImageWithSplitTextOverlap: React.FC<IImageWithSplitTextOverlap> = ({ data }) => {
  const subtitle = data.attrs.subtitle
  const body = data.attrs.body
  const image = data.attrs.image
  const jsonFile = true

  let jsonImage

  try {
    const rawGallery = decodeURIComponent(image)
    const jsonImageRaw = JSON.parse(rawGallery)
    jsonImage = jsonImageRaw.url
  } catch (e) {
    // console.log(e)
  }

  if(jsonImage ===undefined){
  try {
    jsonImage = image.sourceUrl
  } catch (e) {
    // console.log(e)
  }
}
  return (
    <Container>
      <AboutUsWrapper>
        <AboutUsTextBox>
          <Subtitle>{subtitle}</Subtitle>
          <RichTextContainer richText={body} />
        </AboutUsTextBox>
        <AboutUsImgContainer>
          {/* <div
            style={{
              height: "100%",
              width: "auto",
              backgroundImage: `url(${image})`,
              objectFit: "cover",
            }}
          ></div> */}
          {/* <IMG src={image}></IMG> */}

          <LazyLoadImage
            //alt={jsonImage.alt}
            // height={"100%"}
            effect="blur"
            //placeholderStyle={{ opacity: 0 }}
            loading={"eager"}
            src={jsonImage} // use normal <img> attributes as props
            // placeholderSrc={jsonImage.sizes.thumbnail.url}

            // width={"100%"}
          />
        </AboutUsImgContainer>
      </AboutUsWrapper>
    </Container>
  )
}

export default trackWindowScroll(ImageWithSplitTextOverlap)
const AboutUsWrapper = styled.div`
  width: 100%;
  // height: 600px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 54px;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: flex-end;
    height: auto;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    margin-bottom: 0px;
  }
`
const AboutUsImgContainer = styled.div`
  width: 70%;
  height: auto;
  padding-bottom: 120px;
  z-index: 1;
  margin-left: -120px;
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    height: auto;
    margin: auto;
    padding: 0;
  }
`
const IMG = styled.img`
  object-fit: fill;
  height: 100%;
`
const AboutUsTextBox = styled.div`
  width: 55%;
  height: wrap;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
  padding: 60px 65px;
  margin-top: 120px;
  margin-right: -70px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUAgMAAADw5/WeAAAACVBMVEX6+vr4+Pjv7++npPQmAAAAKElEQVQI12NgCGBgZWBgEGBgZACzYWTo0tCoUAiJIs7gwMCCi02CXgD5agzBt66q6wAAAABJRU5ErkJggg==");
  @media (max-width: 768px) {
    width: 100%; //changed by chris street from issue in trello (now not matching design)
    padding: 60px 20px 20px 20px;
    margin: 0;
    align-items: flex-start;
    top: -50px;
  }
  @media (min-width: 768px) {
    //max-width: 560px;
  }
`

const Subtitle = styled.p`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 30px;
  text-transform: uppercase;
  margin-bottom: 15px;
  text-align: left;
  color: black;
`
