import { Container } from "@material-ui/core"
import React from "react"
import styled from "styled-components"
import CrossIcon from "../assets/svg/GFW_cross.svg"

interface ICrossHeading {
  data: any
}

const CrossHeading: React.FC<ICrossHeading> = ({ data }) => {
  const subtitle = data.attrs["subtitle"]
  const title = data.attrs["heading"]
  const text = data.attrs["body"]
  const text2 = data.attrs["body-2"]
  return (
    <Container>
      <CrossHeadingContainer>
        <Cross />
        <CrossHeadingTitlesContainer>
          <CrossHeadingSubtitle>{subtitle} </CrossHeadingSubtitle>
          <h4>{title}</h4>
        </CrossHeadingTitlesContainer>

        <BodyContainer>
          <CrossHeadingText>{text}</CrossHeadingText>
          {text2 && <p>{text2}</p>}
        </BodyContainer>
      </CrossHeadingContainer>
    </Container>
  )
}

export default CrossHeading

const CrossHeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 50px;
  margin-top: 50px;
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    left: 0px;
    align-items: left;
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    margin-bottom: 0px;
    margin-top: 32px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 20px;
  }
`
const Cross = styled(CrossIcon)`
  max-width: 130px;
  max-height: 130px;
  @media (max-width: 768px) {
    width: 80px;
    height: 80px;
  }
`

const BodyContainer = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  padding-top: 0px;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: 100%;
    padding-top: 0px;
  }
`
const CrossHeadingTitlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 30%;
  padding: 0 20px;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: 100%;
    padding: 10px 0px;
  }
`

const CrossHeadingSubtitle = styled.h6`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 17px;
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 14px;
  }
  margin-bottom: 5px;
`

const CrossHeadingText = styled.p`
  font-weight: 500;
  font-size: 18px !important;
`
