import React from "react"
import styled from "styled-components"

function getId(url) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
  const match = url.match(regExp)
  return match && match[2].length === 11 ? match[2] : null
}

function getVimeoId(url) {
  // Look for a string with 'vimeo', then whatever, then a
  // forward slash and a group of digits.
  const match = /vimeo.*\/(\d+)/i.exec(url)

  // If the match isn't null (i.e. it matched)
  if (match) {
    // The grouped/matched digits from the regex
    return match[1]
  }
}

export const VideoLazyBlock = ({ data }) => {
  const videoSrcURL = data.attrs["url"]
  // const videoTitle = data.attrs[""]
  const provider = data.attrs["providerNameSlug"]
  let videoEmbedSrc = ""
  if (provider == "youtube" || provider == "embed-handler") {
    const videoId = getId(videoSrcURL)
    videoEmbedSrc = "//www.youtube.com/embed/" + videoId
  } else if (provider == "vimeo") {
    const videoId = getVimeoId(videoSrcURL)
    videoEmbedSrc = "https://player.vimeo.com/video/" + videoId + "?embedparameter=true"
  }

  return (
    <AspectRatio>
      <VideoContainer className="video">
        <iframe
          src={videoEmbedSrc}
          title={videoSrcURL}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          width="100%"
          height="100%"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
        />
      </VideoContainer>
    </AspectRatio>
  )
}

const AspectRatio = styled.div`
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  /* border: 1px solid red; */
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
`

const VideoContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  min-height: 200px;
  height: 100%;

  .embedVideo-container {
    width: 100%;
    height: 100%;
  }
`
