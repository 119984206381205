import React from "react"
import styled from "styled-components"
import { RichTextContainer } from "./RichTextContainer"
import { Container } from "@material-ui/core"

import { VideoLazyBlock } from "./VideoLazyBlock"

interface ISplitImageVideoWithText {
  data: any
}
export const SplitImageVideoWithText: React.FC<ISplitImageVideoWithText> = ({ data }) => {
  const subtitle = data.attrs.subtitle
  const body = data.attrs.body
  const placeholderImage = data.attrs.placeholderImage
  const videoLink = data.attrs["video-link"]
  let videoProvider
  if (videoLink != undefined) {
    if (videoLink.indexOf("vimeo") > -1) {
      videoProvider = "vimeo"
    } else {
      videoProvider = "youtube"
    }
  }
  return (
    <Container>
      <AboutUsWrapper>
        <VideoContainer>
          {videoLink ? (
          <VideoLazyBlock data={{ attrs: { url: videoLink, providerNameSlug: videoProvider } }} />
          ):(
            <>{ placeholderImage && <img src={placeholderImage.sourceUrl} />}</>
          )}
          
        </VideoContainer>
        <AboutUsTextBox>
          <Subtitle>{subtitle}</Subtitle>
          <RichTextContainer richText={body} />
        </AboutUsTextBox>
      </AboutUsWrapper>
    </Container>
  )
}

const AboutUsWrapper = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 99px;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    flex-direction: column-reverse;
    padding: 20px 0;
    height: auto;
  }
`
const VideoContainer = styled.div`
  width: 55%;
  padding: 0;

  z-index: 2;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    flex-direction: column-reverse;
    width: 100%;
    margin: auto;
    height: wrap;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
  }
`

const AboutUsTextBox = styled.div`
  width: 45%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
  padding: 22px 30px;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: 100%;
    padding: 20px 0px 0px 0px;

    align-items: flex-start;
  }
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    max-width: 560px;
  }
`

const Subtitle = styled.p`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 30px;
  text-transform: uppercase;
  margin-bottom: 15px;
  text-align: left;
  color: black;
`
